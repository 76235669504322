import * as React from "react"
import Helmet from "react-helmet"
import Layout from "../components/layout"
import Button from "../components/button"
import MainMenu from "../components/mainMenu"
import HeroBanner from "../components/heroBanner"
import Footer from "../components/footer"
import Video from "../components/video"

import ReactTooltip from 'react-tooltip'

import { throttle } from 'lodash';

import * as styles from "./styles/product.module.css"
import ContentContainer from "../components/contentContainer"

//import TawkMessengerReact from '@tawk.to/tawk-messenger-react';

import assetIconSelected from "../assets/images/icon-selected.svg";
import assetIconSecure from "../assets/images/icon-secure.svg";
import assetFlare from "../assets/images/bg-flare-pot.svg";

import assetImageProduct from "../assets/images/product-illustration.svg";
import assetImageProductFull from "../assets/images/product-kitstart-set-image-1.jpg";

import productData from "../components/productData"
import assetIconInfo from "../assets/images/icon-info.svg";
import assetIconHeart from "../assets/images/icon-heart-1.svg";

import IframeResizer from 'iframe-resizer-react'

import assetIconClose from "../assets/images/icon-close-button.svg";

import Modal from 'react-modal';

Modal.setAppElement("body");

// markup
class ProductsPage extends React.Component {

  constructor(props) {

    super(props);
    let self = this;

    this.isBrowser = typeof window !== "undefined";
    this.purchaseRef = React.createRef();
    //this.tawkMessengerRef = React.createRef();
    //<TawkMessengerReact propertyId="62b2d7927b967b117995e78c" widgetId="1g659k5j7" ref={this.tawkMessengerRef} />
    this.throttledScrollFunction = throttle(() => { this.handleScroll() }, 1000);

    this.state = {
      selectedProduct: 0,
      selectedInclusion: 0,
      selectorCollapsed: false,
      products: productData.products,
      floatingBarVisible: false,
      tawkReady: false,
      productModelOpen: false,
      productModelContent: {
        title: "",
        subtitle: "",
        image: "",
        body: "",
        disclaimer: "",
        list: []
      }
    };

  }

  componentDidMount() {
    if (this.isBrowser) {
      window.addEventListener('scroll', this.throttledScrollFunction);
      window.addEventListener('resize', this.throttledScrollFunction);
    }

    //if this.tawkMessengerRef.current.onLo()
  }

  componentWillUnmount() {
    if (this.isBrowser) {
      window.removeEventListener('scroll', this.throttledScrollFunction);
      window.removeEventListener('resize', this.throttledScrollFunction);
    }
  }

  isTawkReady() {
    try {
      let ready = this.tawkMessengerRef.current.isChatHidden();
    } catch (error) {
      return false;
    }

    return true;
  }


  handleScroll(event) {

    if (this.isBrowser && this.purchaseRef) {

      let pos = this.purchaseRef.current.getBoundingClientRect().top;
      let buffer = 500;

      /*console.log("####");
      console.log(pos);
      console.log(window.scrollY);
      console.log(window.innerHeight);
      console.log("####");*/

      if (pos >= 0 - buffer) {
        this.setState({ floatingBarVisible: false });

      }
      else {
        this.setState({ floatingBarVisible: true });
      }
    }
  }

  selectProduct = (option) => {
    this.setState({ selectedProduct: option });
    console.log(this.state.selectedProduct);

    setTimeout(() => { ReactTooltip.rebuild(); }, 100);
  }

  productModelUpdate(content) {
    this.setState({ productModelContent: content });
  }

  productModalOpen() {

    this.setState({ productModelOpen: true });

  }

  productModalClose() {

    this.handleScroll();

    this.setState({ productModelOpen: false });

    console.log("Close");
  }

  render() {

    return (
      <>
        <Layout>
          <Helmet title="What's Inside" defer={false}>
            <script src="https://js.afterpay.com/afterpay-1.x.js" data-analytics-enabled async></script>
          </Helmet>
          <ReactTooltip effect="solid" place="right" backgroundColor="#F4E6B2" textColor="#745C1E" />
          <div className={`${styles.flarePot}`}>
            <div className={`${styles.flarePotInner}`}>
              <img src={assetFlare} />
            </div>
          </div>
          <MainMenu pathname={this.props.location.pathname}></MainMenu>
          <ContentContainer>
            <h1 className={`bottomGutter `}>The {this.state.products[this.state.selectedProduct].name} Kitchenware Kit</h1>
            <div className={`${styles.packDetails}`}>
              <div className={`${styles.packDetailsInnerContainer}`}>
                <img className={`${styles.packImage}`} src={assetImageProduct} />
                <div className={`${styles.packContent}`}>
                  <p>{this.state.products[this.state.selectedProduct].description}</p>
                  <div ref={this.purchaseRef} className={`${styles.priceOrderer}`}>
                    <Button variant="primary" extTo={"https://share.hsforms.com/1P4bD5A_OTpqPhMl1o4Ow5wnvji3"}>Notify me</Button>
                    <div className={`${styles.pricing}`}>
                      <div className={`${styles.purchasePrice}`}>${this.state.products[this.state.selectedProduct].price} {this.state.products[this.state.selectedProduct].deliveryStatement}</div>
                      <div className={`${styles.purchaseSubline}`}>
                        <div>
                          <afterpay-placement
                            data-locale="en_AU"
                            data-currency="AUD"
                            data-amount={`${this.state.products[this.state.selectedProduct].price}`}
                          ></afterpay-placement>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ContentContainer>

          <ContentContainer>
            <h2>What's included</h2>
            <div className={`${styles.sceneContainer}`}>
              <img className={`${styles.packImageFull}`} src={assetImageProductFull} />
              <div className={`${styles.sceneDescription}`}>Each kit is unique, with high quality items selected when we pack your order. While you might receive different brands or styles than seen here, you’ll always get a complete and feature-packed kit with the items described below.</div>
            </div>
          </ContentContainer>

          <ContentContainer>
            <div className={`${styles.allInclusions}`}>
              {this.state.products[this.state.selectedProduct].packGroups.map((packGroup) => (

                <div className={`${styles.packGroup}`} key={JSON.stringify(packGroup)}>
                  <h3>{packGroup.title}</h3>
                  {packGroup.subtitle && <p className={`${styles.packGroupSubtitle}`}>{packGroup.subtitle}</p>}
                  <div className={`${styles.packGroupItems}`}>
                    {packGroup.packItems.map((packItem) => (
                      <div className={`${styles.packItem}`} key={JSON.stringify(packItem)}>
                        <div className={`${styles.packItemImage}`}>
                          <img src={`${packItem.images.thumbnail}`} />
                        </div>
                        <div className={`${styles.packItemDetails}`}>
                          <div className={`${styles.packItemTitle} ${packItem.highlight ? styles.highlight : ""}`} {...packItem.highlight && { 'data-tip': packItem.highlight }}>{packItem.name}</div>
                          <p className={`${styles.packItemDescription}`}>{packItem.teaser}</p>
                        </div>
                        {packItem.description.length > 0 && <div className={`${styles.packItemInfo}`}>
                          <a href="#" onClick={(e) => { e.preventDefault(); this.productModelUpdate({ title: packItem.teaser, image: packItem.images.full, body: packItem.description, disclaimer: packItem.disclaimer }); this.productModalOpen(); }}>
                            <img src={assetIconInfo} />
                          </a>
                        </div>}
                      </div>
                    ))}
                  </div>
                </div>

              ))}

            </div>

          </ContentContainer>

          <HeroBanner>
            <img src={assetIconHeart} alt="Heart icon" />
            <h2 className={`${styles.bottomHeading}`}>Half of all profits help those in need.</h2>
            <p>Your purchase helps provide essential kitchenware to people displaced due to natural disaster, domestic violence and more.</p>
          </HeroBanner>

          <Footer floatingBuffer={true} />
          {this.state.floatingBarVisible && <div className={`${styles.floatingBannerContainer}`}>
            <ContentContainer>
              <div className={`${styles.floatingInnerContainer}`}>
                <div className={`${styles.floatingTitle}`}>
                  {this.state.products[this.state.selectedProduct].fullName}
                </div>
                <div className={`${styles.floatingPurchase}`}>
                  <div className={`${styles.floatingPriceOptions}`}>
                    <div className={`${styles.floatingPrice}`}>${this.state.products[this.state.selectedProduct].price} + delivery</div>
                    <div className={`${styles.floatingPriceSubtitle}`}>✕ Out of stock</div>
                  </div>
                  <div className={`${styles.floatingPurchaseCTA}`}>
                    <Button variant="primary" extTo={"https://share.hsforms.com/1P4bD5A_OTpqPhMl1o4Ow5wnvji3"}>Notify me</Button>
                  </div>
                </div>
              </div>
            </ContentContainer>
          </div>}
        </Layout>
      </>
    )
  }

}


export default ProductsPage