import assetIconKitchenwarePan from "../assets/images/items/icon-kitchenware-pan.svg";
import assetIconKitchenwareSaucepan from "../assets/images/items/icon-kitchenware-saucepan.svg";
import assetIconKitchenwarePot from "../assets/images/items/icon-kitchenware-pot.svg";
import assetIconKitchenwareKnife from "../assets/images/items/icon-kitchenware-knife.svg";
import assetIconKitchenwareGrater from "../assets/images/items/icon-kitchenware-grater.svg";
import assetIconKitchenwarePeeler from "../assets/images/items/icon-kitchenware-peeler.svg";
import assetIconKitchenwareChoppingBoard from "../assets/images/items/icon-kitchenware-chopping-board.svg";
import assetIconKitchenwareOvenDish from "../assets/images/items/icon-kitchenware-oven-dish.svg";
import assetIconKitchenwareTray from "../assets/images/items/icon-kitchenware-tray.svg";
import assetIconKitchenwareGlove from "../assets/images/items/icon-kitchenware-glove.svg";
import assetIconKitchenwareTurner from "../assets/images/items/icon-kitchenware-turner.svg";
import assetIconKitchenwareTongs from "../assets/images/items/icon-kitchenware-tongs.svg";
import assetIconKitchenwareSpoon from "../assets/images/items/icon-kitchenware-spoon.svg";
import assetIconKitchenwareSlottedSpoon from "../assets/images/items/icon-kitchenware-slotted-spoon.svg";
import assetIconKitchenwareLadle from "../assets/images/items/icon-kitchenware-ladle.svg";
import assetIconKitchenwareColander from "../assets/images/items/icon-kitchenware-colander.svg";
import assetIconKitchenwareBowl from "../assets/images/items/icon-kitchenware-bowl.svg";
import assetIconKitchenwareMeasuringCups from "../assets/images/items/icon-kitchenware-measuring-cups.svg";
import assetIconKitchenwareMeasuringSpoons from "../assets/images/items/icon-kitchenware-measuring-spoons.svg";
import assetIconKitchenwareTrivets from "../assets/images/items/icon-kitchenware-trivets.svg";
import assetIconKitchenwareCanOpener from "../assets/images/items/icon-kitchenware-can-opener.svg";
import assetIconKitchenwareCorkscrew from "../assets/images/items/icon-kitchenware-can-corkscrew.svg";
import assetIconKitchenwareWhisk from "../assets/images/items/icon-kitchenware-whisk.svg";
import assetIconKitchenwareTeatowel from "../assets/images/items/icon-kitchenware-teatowel.svg";

import assetPhoto1 from "../assets/images/items/photos/tefal_26_frypan_1.jpg";
import assetPhoto2 from "../assets/images/items/photos/scanpan_20_saucepan_1.jpg";
import assetPhoto3 from "../assets/images/items/photos/scanpan_26_stewpot_1.jpg";




export default
  {
    products:
      [
        {
          name: "Essentials",
          fullName: "Essentials Kit",
          available: true,
          price: "399",
          deliveryStatement: "+ delivery",
          currency: "AUD",
          purchaseLink: {
            label: "Purchase",
            url: "https://google.com"
          },
          images: {
            thumbnail: "",
            full: ""
          },
          teaser: "Exactly what you need to move in and cook. A great starting point for your kitchen.",
          description: "High quality kitchenware delivered without visiting multiple stores. Includes essential cookware, ovenware, utensils, and more. Great for those leaving the nest or couples starting a new chapter, it also makes a wonderful gift to get your loved ones started.",
          packGroups: [
            {
              title: "Cookware",
              subtitle: "",
              packItems: [
                {
                  name: "Frypan",
                  teaser: "Tefal 26cm Ultimate Non-Stick Pan - Made in France",
                  highlight: "You’ll get this exact brand and model",
                  description: "",
                  disclaimer: "Independent retail store - Tefal is a registered trademark of TEFAL.",
                  images: {
                    thumbnail: assetIconKitchenwarePan,
                    full: ""
                  }
                },
                {
                  name: "Small pot",
                  teaser: "Scanpan 20cm Impact Stainless Steel Saucepan with Lid",
                  highlight: "You’ll get this exact brand and model",
                  description: "",
                  disclaimer: "Independent retail store - Scanpan is a registered trademark of SCANPAN A/S.",
                  images: {
                    thumbnail: assetIconKitchenwareSaucepan,
                    full: ""
                  }
                },
                {
                  name: "Large pot",
                  teaser: "Scanpan 26cm Impact Stainless Steel Stewpot with Lid",
                  highlight: "You’ll get this exact brand and model",
                  description: "",
                  disclaimer: "Independent retail seller - Scanpan is a registered trademark of SCANPAN A/S.",
                  images: {
                    thumbnail: assetIconKitchenwarePot,
                    full: ""
                  }
                },
              ]
            },
            {
              title: "Ovenware",
              subtitle: "From Pyrex, Wiltshire, Avanti, Bakemaster or Maxwell & Williams depending on availability.",
              packItems: [
                {
                  name: "Roasting pan / dish",
                  description: "",
                  images: {
                    thumbnail: assetIconKitchenwareOvenDish,
                    full: ""
                  }
                },
                {
                  name: "Baking tray",
                  description: "",
                  images: {
                    thumbnail: assetIconKitchenwareTray,
                    full: ""
                  }
                },
                {
                  name: "Oven mitt",
                  description: "",
                  images: {
                    thumbnail: assetIconKitchenwareGlove,
                    full: ""
                  }
                },
              ]
            },
            {
              title: "Cutting and preparation",
              subtitle: "From Victorinox, OXO, Scanpan, Avanti or Chef Inox depending on availability.",
              packItems: [
                {
                  name: "Knives",
                  teaser: "Victorinox 3pc Paring Knife Set",
                  highlight: "You’ll get this exact brand and model",
                  description: "",
                  images: {
                    thumbnail: assetIconKitchenwareKnife,
                    full: ""
                  }
                },
                {
                  name: "Grater",
                  description: "",
                  images: {
                    thumbnail: assetIconKitchenwareGrater,
                    full: ""
                  }
                },
                {
                  name: "Peeler",
                  description: "",
                  images: {
                    thumbnail: assetIconKitchenwarePeeler,
                    full: ""
                  }
                },
                {
                  name: "Colander",
                  description: "",
                  images: {
                    thumbnail: assetIconKitchenwareColander,
                    full: ""
                  }
                },
                {
                  name: "Chopping board",
                  description: "",
                  images: {
                    thumbnail: assetIconKitchenwareChoppingBoard,
                    full: ""
                  }
                },
              ]
            },
            {
              title: "Cooking utensils",
              subtitle: "From KitchenAid, OXO, Wiltshire, Avanti or Chef Inox depending on availability.",
              packItems: [
                {
                  name: "Tongs",
                  description: "",
                  images: {
                    thumbnail: assetIconKitchenwareTongs,
                    full: ""
                  }
                },
                {
                  name: "Turner",
                  description: "",
                  images: {
                    thumbnail: assetIconKitchenwareTurner,
                    full: ""
                  }
                },
                {
                  name: "Spoon",
                  description: "",
                  images: {
                    thumbnail: assetIconKitchenwareSpoon,
                    full: ""
                  }
                },
                {
                  name: "Slotted spoon",
                  description: "",
                  images: {
                    thumbnail: assetIconKitchenwareSlottedSpoon,
                    full: ""
                  }
                },
                {
                  name: "Ladle",
                  description: "",
                  images: {
                    thumbnail: assetIconKitchenwareLadle,
                    full: ""
                  }
                }
              ]
            },
            {
              title: "Measuring and mixing",
              subtitle: "From OXO, Pyrex, Scanpan, Avanti or Chef Inox depending on availability.",
              packItems: [
                {
                  name: "Mixing bowl",
                  description: "",
                  images: {
                    thumbnail: assetIconKitchenwareBowl,
                    full: ""
                  }
                },
                {
                  name: "Measuring cups",
                  description: "",
                  images: {
                    thumbnail: assetIconKitchenwareMeasuringCups,
                    full: ""
                  }
                },
                {
                  name: "Measuring spoons",
                  description: "",
                  images: {
                    thumbnail: assetIconKitchenwareMeasuringSpoons,
                    full: ""
                  }
                },
              ]
            },
            {
              title: "Extras",
              subtitle: "",
              packItems: [
                {
                  name: "Trivets",
                  teaser: "2pc heat resistant pads",
                  description: "",
                  images: {
                    thumbnail: assetIconKitchenwareTrivets,
                    full: ""
                  }
                },
              ]
            },
          ]
        }
      ]
  }